import { Control } from 'ol/control';

class LinkButton extends Control {
  constructor(opt_options) {
    var options = opt_options || {};

    var element = document.createElement('div');
    element.className = 'ol-unselectable ol-control control-btn-lnk';
    if (options.className) {
      element.classList.add(options.className);
    }

    options.element = element;

    super(options);

    this.link = document.createElement('a');
    this.link.innerHTML = options.innerHTML || '?';
    if (options.title) {
      this.link.title = options.title;
      this.link.setAttribute('aria-label', options.title);
    }
    this.link.href = options.href || '#';
    element.appendChild(this.link);

  }

  getLink() {
    return this.link;
  }

}

export { LinkButton };
