import { Control } from 'ol/control';

class Button extends Control {
  constructor(opt_options) {
    var options = opt_options || {};

    var element = document.createElement('div');
    element.className = 'ol-unselectable ol-control btn-control';
    if (options.className) {
      element.classList.add(options.className);
    }

    options.element = element;

    super(options);

    this.button = document.createElement('button');
    this.button.innerHTML = options.innerHTML || '?';
    if (options.title) {
      this.button.title = options.title;
      this.button.setAttribute('aria-label', options.title);
    }
    this.button.addEventListener('click', (evt) => {
      this.dispatchEvent({type: 'click'});
    }, false);
    element.appendChild(this.button);

    this.active = this.setActive(!!options.active);
  }

  setActive(active) {
    var activeClass = 'btn-active';
    this.active = active;
    if (this.active) {
      this.element.classList.add(activeClass);
    } else {
      this.element.classList.remove(activeClass);
    }
    this.dispatchEvent({
      type: 'active',
      active: this.active
    });
    return this.active;
  }

  getActive() {
    return this.active;
  }

}

export { Button };
