<script>
  import Plausible from 'plausible-tracker';
  const {trackPageview} = Plausible({
    domain: 'heatmap.data.gov.scot',
    // trackLocalhost: true
  });

  let mode = 'choose';
  let data = null;

  import { createEventDispatcher } from 'svelte';
  import { formatArea, formatHeatDemand, roundTo } from './formatting.js';

  const dispatch = createEventDispatcher();

  export function setMode(mode_, data_) {
    mode = mode_;
    data = data_;
    if (mode === 'choose') {
      dispatch('choose');
    }
    if (mode === 'custom-draw') {
      dispatch('custom-draw');
    }
    if (mode.endsWith('Datazone')) {
      dispatch('Datazone-select');
    }
    if (mode.endsWith('Settlement')) {
      dispatch('Settlement-select');
    }
    if (mode.endsWith('LA')) {
      dispatch('LA-select');
    }
    if (mode === 'select-loading') {
      dispatch('select-loading');
    }
    if (mode === 'custom-clear') {
      dispatch('custom-clear');
    }
    if (mode === 'datazone-clear') {
      dispatch('datazone-clear');
    }
    if (mode === 'settlement-clear') {
      dispatch('settlement-clear');
    }
    if (mode === 'la-clear') {
      dispatch('la-clear');
    }
    if (mode === 'close') {
      dispatch('close');
    }
  }

  function printReport() {
    if (mode === 'Settlement-select-result') {
        trackPageview({url: 'https://heatmap.data.gov.scot/reports/print/settlement'});
    }
    if (mode === 'Datazone-select-result') {
        trackPageview({url: 'https://heatmap.data.gov.scot/reports/print/datazone'});
    }
    if (mode === 'LA-select-result') {
        trackPageview({url: 'https://heatmap.data.gov.scot/reports/print/localauthority'});
    }
    dispatch('print-report');
   }

  function printCustomReport() {
    trackPageview({url: 'https://heatmap.data.gov.scot/reports/print/custom'});
    dispatch('print-custom-report');
   }

  function getCSV(data) {
    if (mode === 'custom-result') {
        trackPageview({url: 'https://heatmap.data.gov.scot/reports/csv/custom'});
    }
    if (mode === 'Settlement-select-result') {
        trackPageview({url: 'https://heatmap.data.gov.scot/reports/csv/settlement'});
    }
    if (mode === 'Datazone-select-result') {
        trackPageview({url: 'https://heatmap.data.gov.scot/reports/csv/datazone'});
    }
    if (mode === 'LA-select-result') {
        trackPageview({url: 'https://heatmap.data.gov.scot/reports/csv/localauthority'});
    }
    let csv = generateCSV(data);
    downloadCSV(csv);
   }
 
  function generateCSV(data) {
    let reportLayer = data[0] ? data[0] : data;
    if (reportLayer[0]) {
        if (reportLayer[0].get('datazone2011')) {
            var table = [
                ['Data Zone', 'Name', 'Area (km2)', 'Energy supply count', 'Public-sector demand (kWh/y)', 'Heat demand density (kWh/y/m2)', 'Total heat demand (kWh/y)']
            ];
        } else {
            var table = [
                ['Name', 'Area (m2)', 'Energy supply count', 'Public-sector demand (kWh/y)', 'Heat demand density (kWh/y/m2)', 'Total heat demand (kWh/y)']
            ];
        }
        reportLayer.forEach(function(f) {
            let row = [];
            if (f.get('datazone2011')) {
                row.push(f.get('datazone2011'));
            }
            if (f.get('name')) {
                row.push(`"${f.get('name')}"`);
            } else if (f.get('settlementname')) {
                row.push(`"${f.get('settlementname')}"`);
            } else {
                row.push(`"${f.get('local_auth')}"`);
            }
            if (f.get('area')) {
                row.push(f.get('area'));
            } else if (f.get('area_m2')) {
                row.push(f.get('area_m2'));
            } else {
                row.push(f.get('area_km2'));
            }
            row.push(f.get('energy_supply_count'));
            row.push(f.get('public_sector_count'));
            row.push(f.get('demanddensity_kwh_y_m2'));
            row.push(f.get('totalheatdemand_kwh_y'));
            table.push(row);
        })
    } else {
        var table = [
            ['Data Zones', 'Local Authorities', 'Area (m2)', 'Energy supply count', 'Public-sector demand (kWh/y)', 'Heat demand density (kWh/y/m2)', 'Total heat demand (kWh/y)']
        ];
        let row = [];
        row.push(`"${reportLayer.datazones}"`);
        row.push(`"${reportLayer.authorities}"`);
        row.push(reportLayer.area);
        row.push(reportLayer.supply);
        row.push(reportLayer.publicsectordemand);
        row.push(reportLayer.heatdemand/reportLayer.area);
        row.push(reportLayer.heatdemand);
        table.push(row);
    }
    return table.join('\n');
  }
    const downloadCSV = csv => {
      const csvFile = new Blob([csv], { type: 'text/csv' })
      const downloadLink =  document.createElement('a')

      downloadLink.download = `ScotlandHeatDemand-${new Date().toDateString()}.csv`
      downloadLink.href = window.URL.createObjectURL(csvFile)
      downloadLink.style.display = 'none'
      document.body.appendChild(downloadLink)

      downloadLink.click()
    }
</script>

<main>
  {#if mode === 'choose'}
    <button class="toggle" on:click={(evt) => setMode('custom-draw')}>Draw custom area</button>
    <button class="toggle" on:click={(evt) => setMode('select-Datazone')}>Select Data Zones</button>
    <button class="toggle" on:click={(evt) => setMode('select-Settlement')}>Select settlements</button>
    <button class="toggle" on:click={(evt) => setMode('select-LA')}>Select Local Authorities</button>
  {/if}
  {#if mode.startsWith('custom')}
    <h2>Custom area</h2>
    {#if mode === 'custom-draw'}
    <p>Draw an area on the map by clicking to add points. Double-click, or click on your first point, to finish drawing. You can still pan and zoom the map while drawing your report area.</p>
    <button class="toggle closer" on:click={(evt) => setMode('custom-clear')}>Reset report area</button>
    {/if}
    {#if mode === 'custom-loading'}
      <p>⏳ Calculating...</p>
    {/if}
    {#if mode === 'custom-result'}
      <table class="resultScreen">
        <tr>
          <th>Public Sector Heat Demand</th>
          <td>{formatHeatDemand(data.publicsectordemand)}</td>
        </tr>
        <tr>
          <th>Heat Demand</th>
          <td>{formatHeatDemand(data.heatdemand)}</td>
        </tr>
        <tr>
          <th>Heat Demand Density</th>
          <td>{formatHeatDemand(data.heatdemand/data.area)}/m&sup2;</td>
        </tr>
        <tr>
          <th>Energy supplies</th>
          <td>{data.supply}</td>
        </tr>
        <tr>
          <th>Area</th>
          <td>{formatArea(data.area)}</td>
        </tr>
        <tr>
          <th>Local Authorities</th>
          <td>{data.authorities.join(', ')}</td>
        </tr>
        <tr>
          <th>Data Zones</th>
          <td><span>{data.datazones.join(', ')}</span></td>
        </tr>
      </table>
      <table class="resultPrint">
        <tr>
          <th>Public Sector Heat Demand</th>
          <td>{formatHeatDemand(data.publicsectordemand)}</td>
          <th>Heat Demand density/<br />Total</th>
          <td>{formatHeatDemand(data.heatdemand/data.area)}/m&sup2;<br />
              {formatHeatDemand(data.heatdemand)}</td>
        </tr>
        <tr>
          <th>Energy supplies</th>
          <td>{data.supply}</td>
          <th>Area</th>
          <td>{formatArea(data.area)}</td>
        </tr>
        <tr>
          <th>Local Authorities</th>
          <td colspan="3">{data.authorities.join(', ')}</td>
        </tr>
        <tr>
          <th>Data Zones</th>
          <td colspan="3"><span>{data.datazones.join(', ')}</span></td>
        </tr>
      </table>
    <button class="toggle" id="printReportButton" on:click={(evt) => printCustomReport()}>Print report</button>
    <button class="toggle" on:click={(evt) => getCSV(data)}>Download CSV</button>
    <button class="toggle closer" on:click={(evt) => setMode('custom-clear')}>Reset report area</button>
    {/if}
  {/if}
  {#if mode.startsWith('select')}
    <p>Click on the map to select one or more features to report on. You can still pan and zoom the map while selecting your report areas.</p>
    <button class="toggle closer" on:click={(evt) => setMode('custom-clear')}>Reset report area</button>
  {/if}
  {#if mode === 'select-loading'}
    <p>⏳ Calculating...</p>
  {/if}
  {#if mode === 'Settlement-select-result'}
    <table>
      <tr>
        <th>Settlement</th>
        <th class="numberAlign">Area (km²)</th>
        <th class="numberAlign">Energy supplies</th>
        <th class="numberAlign">Public sector demand</th>
        <th class="numberAlign">Heat demand density/<br />total</th>
      </tr>
    {#each data[0] as settlement}
      <tr>
        <td>{settlement.get('settlementname')}</td>
        <td class="numberAlign">{roundTo(settlement.get('area_m2')/1000000, 2)}</td>
        <td class="numberAlign">{settlement.get('energy_supply_count')}</td>
        <td class="numberAlign">{formatHeatDemand(settlement.get('public_sector_count'))}</td>
        <td class="numberAlign">{formatHeatDemand(settlement.get('demanddensity_kwh_y_m2'))}/m&sup2;<br />
        {formatHeatDemand(settlement.get('totalheatdemand_kwh_y'))}</td>
      </tr>
    {/each}
      <tr class="total">
        <td>TOTAL: </td>
        <td class="numberAlign">{roundTo(data[1]/1000000, 2)}</td>
        <td class="numberAlign">{data[3]}</td>
        <td class="numberAlign">{formatHeatDemand(data[4])}</td>
        <td class="numberAlign">{formatHeatDemand(data[5])}/m&sup2;<br />
                                {formatHeatDemand(data[2])}</td>
    </table>
    <button class="toggle" id="printReportButton" on:click={(evt) => printReport()}>Print report</button>
    <button class="toggle" on:click={(evt) => getCSV(data)}>Download CSV</button>
    <button class="toggle closer" on:click={(evt) => setMode('settlement-clear')}>Reset report area</button>
  {/if}
  {#if mode === 'Datazone-select-result'}
    <table>
      <tr>
        <th class="numberAlign">Area</th>
        <th class="numberAlign">Energy supplies</th>
        <th class="numberAlign">Public-sector heat demand</th>
        <th class="numberAlign">Heat demand density/<br />total</th>
      </tr>
    {#each data[0] as datazone}
      <tr class="datazoneName">
        <td colspan="4">{datazone.get('datazone2011')} {datazone.get('name')}</td>
      </tr>
      <tr>
        <td class="numberAlign">{formatArea(datazone.get('area_km2'), 'Datazone_Heat_Demand')}</td>
        <td class="numberAlign">{datazone.get('energy_supply_count')}</td>
        <td class="numberAlign">{formatHeatDemand(datazone.get('public_sector_count'))}</td>
        <td class="numberAlign">{formatHeatDemand(datazone.get('demanddensity_kwh_y_m2'))}/m&sup2;<br />
                                {formatHeatDemand(datazone.get('totalheatdemand_kwh_y'))}</td>
      </tr>
    {/each}
      <tr class="total">
        <td class="numberAlign">{formatArea(data[1]/1000000, 'Datazone_Heat_Demand')}</td>
        <td class="numberAlign">{data[3]}</td>
        <td class="numberAlign">{formatHeatDemand(data[4])}</td>
        <td class="numberAlign">{formatHeatDemand(data[5])}/m&sup2;<br />
                                {formatHeatDemand(data[2])}</td>
    </table>
    <button class="toggle" id="printReportButton" on:click={(evt) => printReport()}>Print report</button>
    <button class="toggle" on:click={(evt) => getCSV(data)}>Download CSV</button>
    <button class="toggle closer" on:click={(evt) => setMode('datazone-clear')}>Reset report area</button>
  {/if}
  {#if mode === 'LA-select-result'}
    <table>
      <tr>
        <th class="numberAlign">Area</th>
        <th class="numberAlign">Energy supplies</th>
        <th class="numberAlign">Public-sector heat demand</th>
        <th class="numberAlign">Heat demand density/<br />total</th>
      </tr>
    {#each data[0] as authority}
      <tr class="datazoneName">
        <td colspan="4">{authority.get('name')}</td>
      </tr>
      <tr>
        <td class="numberAlign">{formatArea(authority.get('area_m2'))}</td>
        <td class="numberAlign">{authority.get('energy_supply_count')}</td>
        <td class="numberAlign">{formatHeatDemand(authority.get('public_sector_count'))}</td>
        <td class="numberAlign">{formatHeatDemand(authority.get('demanddensity_kwh_y_m2'))}/m&sup2;<br />
                                {formatHeatDemand(authority.get('totalheatdemand_kwh_y'))}</td>
      </tr>
    {/each}
      <tr class="total">
        <td class="numberAlign">{formatArea(data[1])}</td>
        <td class="numberAlign">{data[3]}</td>
        <td class="numberAlign">{formatHeatDemand(data[4])}</td>
        <td class="numberAlign">{formatHeatDemand(data[5])}/m&sup2;<br />
                                {formatHeatDemand(data[2])}</td>
    </table>
    <button class="toggle" id="printReportButton" on:click={(evt) => printReport()}>Print report</button>
    <button class="toggle" on:click={(evt) => getCSV(data)}>Download CSV</button>
    <button class="toggle closer" on:click={(evt) => setMode('la-clear')}>Reset report area</button>
  {/if}
    <button class="toggle closer" on:click={(evt) => setMode('close')}>&times; Close report tool</button>
</main>
