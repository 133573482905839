function formatHeatDemand(kwh) {
  // Value is in Kilowatt-hours per year
  var mwh = kwh/1000;
  var gwh = kwh/1000000;
  var twh = kwh/1000000000;
  if (twh > 1) {
    return roundTo(twh, 2).toLocaleString() + ' TWh/yr';
  } else if (gwh > 1) {
    return roundTo(gwh, 2).toLocaleString() + ' GWh/yr';
  } else if (mwh > 1) {
    return roundTo(mwh, 2).toLocaleString() + ' MWh/yr';
  } else {
    return roundTo(kwh, 2).toLocaleString() + ' KWh/yr';
  }
}

function formatArea(area, layerName) {
  // Value is in m²
  if (layerName == 'Datazone_Heat_Demand') {
    return roundTo(area, 2) + ' km²';
  }
  if (area >= 10000) {
    return roundTo(area / 1000000, 2) + ' km²';
  } else {
    return roundTo(area, 2) + ' m²';
  }
}

function roundTo(num, places) {
    var p = Math.pow(10, places);
    return Math.round(num * p) / p;
}

export { formatArea, formatHeatDemand, roundTo };
