<script>
  // Allows dispatching events via our parent (WelcomePanel)
  export let dispatch = (evt) => {};
  export let mode = '';

  function setMode(mode_) {
    mode = mode_;
    dispatch(mode);
  }
</script>

<main>
    <img src="https://heatmap.data.gov.scot/custom/heatmap/img/scotgov_logo.jpg" id="logo" alt="" />

    <p>This interactive map and reporting tool allows you to examine where and how much demand there is for heat energy from buildings across Scotland. You can also see where there are existing or planned heat networks, existing or potential energy supply sources and areas with high concentrations of socially renting households. It can be used to identify opportunities to reduce carbon emissions from heat in buildings.</p>

    <p>Heat demand estimates are calculated for each building in Scotland and presented here on a range of areas starting at 50m grid squares up to whole local authority areas. Details of the calculations and data sources used can be found on the <a href="https://www.gov.scot/publications/scotland-heat-map-documents/" target="_blank">Scotland Heat Map documentation web page</a>.</p>
    
    <p>When you leave the site, the current map set up is automatically saved to allow you to return to it on your next visit. You can use the 'reset map' button above to clear this saved state.</p>

    <button id="getStarted" class="toggle" on:click={(evt) => setMode('close')}>Get started</button>
    
    <p>You are granted a non-exclusive, royalty free revocable licence solely to view the licensed data for non-commercial purposes for the period during which the Scottish Government makes it available; You are not permitted to copy, sub-license, distribute, sell or otherwise make available the licensed data to third parties in any form. Third party rights to enforce the terms of this licence shall be reserved to Ordnance Survey (OS).</p>
    
    <p><a href="https://heatmap-data-gov-scot.readthedocs.io/en/latest/accessibility.html" target="_blank">Accessibility statement</a>
</main>
