import { Control } from 'ol/control';

class Panel extends Control {
  constructor(opt_options) {
    var options = opt_options || {};

    var element = document.createElement('div');
    element.className = 'panel ol-unselectable ol-control';
    if (options.className) {
      element.classList.add(options.className);
    }

    super({
      element: element,
      target: options.target,
    });

    var header = (this.header = document.createElement('header'));
    element.appendChild(header);

    var title = (this.title = document.createElement('h1'));
    title.innerHTML = options.title;
    header.appendChild(title);

    var panel = (this.panel = document.createElement('div'));
    panel.classList.add('panel');
    element.appendChild(panel);

  }

  /**
   * Override if you'd like to do something other than immediately
   * hiding the panel when the close button is clicked such as
   * dispatching an event.
   */
  handleCloseClick() {
    this.hide();
  }

  show() {
    this.element.style.visibility = 'visible';
  }

  hide() {
    this.element.style.visibility = 'hidden';
  }
}

export { Panel };
