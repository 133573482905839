import copy from 'copy-to-clipboard';
import mktoast from 'mk-toast';
import Plausible from 'plausible-tracker';

import {Control} from 'ol/control';
import {transformExtent} from 'ol/proj';
import {getGroupContainingLayer} from 'ol-ishare/olutil';
import {iShareUrl} from './main';

// Only specified layers are avaiable to download as CSV
const csvLayers = [
    'Datazone_Heat_Demand',
    'Settlements_Heat_Demand',
    'Local_Authority_Demand',
    'Heat_Networks_1',
    'AnaerobicDigestion',
    'Biomass',
    'EnergyFromWaste',
    'GroundSourceHeatPump',
    'Hydro',
    'LandfillGas',
    'Nuclear',
    'Other',
    'SolarPhotovoltaic',
    'FossilFuel',
    'TidalStream',
    'WaterSourceHeatPump',
    'WavePower',
    'Wind'
];

const geotiffLayers = [
    'hmp_1km',
    'hmp_500m',
    'hmp_250m',
    'hmp_50m',
    'conf_1km',
    'conf_500m',
    'conf_250m',
    'conf_50m',
]

const bgsLayers = [
    'mine_entries',
    'reporting_areas',
    'dev_risk_area',
    'coal_resource_area',
    'surface_mining'
]

const {trackPageview} = Plausible({
  domain: 'heatmap.data.gov.scot',
  // trackLocalhost: true
});


class LayerInfoPanel extends Control {
    constructor(opt_options) {
        var options = opt_options || {};

        var panel = document.createElement('div');
        panel.classList.add("panel");
        var closer = document.createElement('div');
        closer.classList.add('ol-popup-closer');
        panel.appendChild(closer);

        // Layer title
        var layerName = document.createElement('div');
        layerName.classList.add('layerInfoName');
        panel.appendChild(layerName);

        // Last updated (from metadata)
        var layerDate = document.createElement('div');
        layerDate.classList.add('layerDate');
        panel.appendChild(layerDate);

        // GetLegendGraphic
        var layerLegend = document.createElement('div');
        layerLegend.classList.add('layerLegend');
        panel.appendChild(layerLegend);

        // Layer abstract (from metadata)
        var layerAbstract = document.createElement('div');
        layerAbstract.classList.add('layerAbstract');
        panel.appendChild(layerAbstract);

        // Opacity slider
        var opacityDiv = document.createElement('div');
        opacityDiv.classList.add('opacityControl');
        panel.appendChild(opacityDiv);
        
        var opacityLabel = document.createElement('label');
        opacityLabel.classList.add('opacityLabel');
        opacityLabel.innerText = "Opacity: ";
        opacityLabel.setAttribute('for', 'opacitySlider');
        opacityDiv.appendChild(opacityLabel);

        var element = document.createElement('div');
        element.className = 'layerInfoPanel ol-unselectable ol-control';
        element.appendChild(panel);

        super({
            element: element,
            target: options.target,
        });

        closer.addEventListener('click', function() {
            element.style.visibility = "hidden";
        });
    }
    
    static showLayerInfo(lyr, map, panel, openPanel, targetLyr) {
        if (openPanel) {
            panel.style.visibility = "visible";
        }
        
        // Values from layer, unless autoscale, in which case from child layer
        if (targetLyr) {
            var srcLyr = targetLyr;
        } else {
            var srcLyr = lyr;
        }
        
        // Layer title
        panel.querySelector('.layerInfoName').innerText = srcLyr.get('title');
        
        
        // Last updated (from metadata)
        let lastUpdated = new Date(
            srcLyr.get('iShare:config').metadata.modified
        ).toLocaleDateString();
        if (lastUpdated == 'Invalid Date') {
            lastUpdated = 'unknown';
        }
        panel.querySelector('.layerDate').innerText = `Last updated: ${lastUpdated}`;

        // Link to metadata record on remote CSW
        let metadataURL = srcLyr.get('iShare:config').metadata.externalUri;
        
        // Abstract (from metadata)
        if (srcLyr.get('iShare:config').metadata.abstract) {
            panel.querySelector('.layerAbstract').innerHTML = `${srcLyr.get('iShare:config').metadata.abstract.slice(0, 450)}&hellip; `;
            moreLink = panel.querySelector('.moreLink');
            if (moreLink) {
                moreLink.parentNode.removeChild(moreLink);
            }
            var moreLink = document.createElement('a');
            moreLink.classList.add('moreLink');
            moreLink.href = metadataURL;
            moreLink.target = '_blank';
            moreLink.innerText = 'More info...';
            moreLink.addEventListener('click', (evt) => {
              trackPageview({url: `https://heatmap.data.gov.scot/metadata/${srcLyr.get('iShare:layerName')}`});
            })
            panel.querySelector('.layerAbstract').appendChild(moreLink);
        } else {
            panel.querySelector('.layerAbstract').innerText = '';
        }

        // GetLegendGraphic
        layerLegend = panel.querySelector('.layerLegend');
        if (layerLegend) {
            layerLegend.parentNode.removeChild(layerLegend);
        }
        var layerLegend = document.createElement('img');
        layerLegend.classList.add('layerLegend');
        var legendSrc = `${iShareUrl}getows.ashx?mapsource=mapsources/heatmap&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&LAYER=${srcLyr.get('iShare:layerName')}&SCALE=2057143.9679999999`;
        // Patch Energy Supply layers with static SLD to reset displacement
        if (getGroupContainingLayer(map.getLayerGroup(), srcLyr).get('title') == "Energy Supply") {
            let lyrName = srcLyr.get('iShare:layerName');
            legendSrc = legendSrc + `&SLD=../custom/sld/${lyrName}.sld`;
        }
        layerLegend.src = legendSrc;
        // panel.querySelector(".panel").appendChild(layerLegend);
        
        // Dynamic legend
        var div = document.createElement('div');
        div.classList.add('legendWrapper');
        div.classList.add('layerLegend');
        var img = document.createElement('img');
        img.classList.add('legend');
        // img.addEventListener('error', handleImageError);
        img.src = legendSrc;
        div.appendChild(img);
        var div2 = document.createElement('div');
        div2.classList.add('legendIcons');
        div2.classList.add('panelLegendIcons');
        var img2 = document.createElement('img');
        img2.id = `pnl_lgnd_${srcLyr.get('iShare:layerName')}`;
        img2.classList.add('legend');
        // img2.addEventListener('error', handleImageError);
        img2.src = legendSrc;
        img2.style.opacity = srcLyr.getOpacity();
        div2.appendChild(img2);
        div.appendChild(div2);
        panel.querySelector(".panel").appendChild(div);

        // Opacity control
        
        
        var opacityDiv = panel.querySelector('.opacityControl');
        if (opacityDiv) {
            opacityDiv.parentNode.removeChild(opacityDiv);
        }
        var opacityDiv = document.createElement('div');
        opacityDiv.classList.add('opacityControl');
        panel.querySelector('.panel').appendChild(opacityDiv);
        
        var opacityLabel = document.createElement('label');
        opacityLabel.classList.add('opacityLabel');
        opacityLabel.innerText = "Opacity: ";
        opacityLabel.setAttribute('for', 'opacitySlider');
        panel.querySelector(".opacityControl").appendChild(opacityLabel);

        var opacitySlider = document.createElement('input');
        opacitySlider.type = 'range';
        opacitySlider.setAttribute('max', 1);
        opacitySlider.setAttribute('step', 0.01);
        opacitySlider.classList.add('opacitySlider');
        panel.querySelector(".opacityControl").appendChild(opacitySlider);
        
        opacitySlider.value = srcLyr.getOpacity();
        opacitySlider.addEventListener('input', function() {
            opacityDisplay.value = `${Math.round(opacitySlider.valueAsNumber * 100)}%`;
            srcLyr.setOpacity(opacitySlider.valueAsNumber);
            try {
                document.querySelector(`#lgnd_${srcLyr.get('iShare:layerName')}`).style.opacity = opacitySlider.valueAsNumber;
            } catch(err) {
            }
            try {
                document.querySelector(`#pnl_lgnd_${srcLyr.get('iShare:layerName')}`).style.opacity = opacitySlider.valueAsNumber;
            } catch(err) {
            }
        }, false);
        
        var opacityDisplay = document.createElement('output');
        opacityDisplay.id = 'opacityDisplay';
        opacityDisplay.setAttribute('for', 'opacitySlider');
        opacityDisplay.value = `${Math.round(opacitySlider.valueAsNumber * 100)}%`;
        panel.querySelector(".opacityControl").appendChild(opacityDisplay);
        
        // Web services and downloads
        var ogcList = panel.querySelector('#ogcList');
        if (ogcList) {
            ogcList.parentNode.removeChild(ogcList);
        }
        var params = srcLyr.get('source').params_;
        var qs = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
        var ogcUrl = srcLyr.get('iShare:config').metadata.URIs.filter(uri => uri.protocol.startsWith('OGC'))[0].url;
        var wmsUrl = `${ogcUrl}&SERVICE=WMS&REQUEST=GetCapabilities&${qs}`;
        var wfsUrl = `${ogcUrl}&VERSION=2.0.0`;
        
        if (csvLayers.includes(srcLyr.get('iShare:layerName'))) {
            var csvUrl = `/downloads/${srcLyr.get('iShare:config').layerName}.csv`;
            var csvLink = document.createElement('a');
            csvLink.href = csvUrl;
            csvLink.innerText = 'CSV';
            csvLink.target = '_blank';
        }

        if (geotiffLayers.includes(srcLyr.get('iShare:layerName'))) {
            var geotiffUrl = `https://sg-heatmap.s3.eu-west-1.amazonaws.com/${srcLyr.get('iShare:config').layerName}.tiff`;
            var geotiffLink = document.createElement('a');
            geotiffLink.href = geotiffUrl;
            geotiffLink.innerText = 'GeoTIFF';
        }

        if (!bgsLayers.includes(srcLyr.get('iShare:layerName')) && 
            getGroupContainingLayer(map.getLayerGroup(), srcLyr).get('title') != "Boundaries") {
            var ogcLinks = document.createElement('table');
            ogcLinks.id = 'ogcLinks';

            var wfsRow = document.createElement('tr');
            wfsRow.innerHTML = `<td>WFS:</td><td><input value="${wfsUrl}" /></td><td title="Copy WFS address">content_copy</td>`;
            wfsRow.querySelector('td:last-of-type').addEventListener('click', (evt) => {
              let copied = copy(wfsUrl);
              if (copied) {
                trackPageview({url: `https://heatmap.data.gov.scot/wfs/\${srcLyr.get('iShare:layerName')}`});
                mktoast.info('WFS address copied to your clipboard.', {
                  position: 'top left'
                });
              } else {
                mktoast.echo('Unable to copy link to clipboard, try highlighting the link and copying manually.', {
                  position: 'top left'
                });
              }
              evt.preventDefault();
            });
            var wmsRow = document.createElement('tr');
            wmsRow.innerHTML = `<td>WMS:</td><td><input value="${wmsUrl}" /></td><td title="Copy WMS address">content_copy</td>`;
            wmsRow.querySelector('td:last-of-type').addEventListener('click', (evt) => {
              let copied = copy(wmsUrl);
              if (copied) {
                trackPageview({url: `https://heatmap.data.gov.scot/wms/${srcLyr.get('iShare:layerName')}`});
                mktoast.info('WMS address copied to your clipboard.', {
                  position: 'top left'
                });
              } else {
                mktoast.echo('Unable to copy link to clipboard, try highlighting the link and copying manually.', {
                  position: 'top left'
                });
              }
              evt.preventDefault();
            });

            if (!geotiffLayers.includes(srcLyr.get('iShare:layerName')) && srcLyr.get('iShare:layerName') != 'heat_demand') {
                ogcLinks.appendChild(wfsRow);
            }
            ogcLinks.appendChild(wmsRow);
            if (csvLayers.includes(srcLyr.get('iShare:layerName'))) {
                var csvBullet = document.createElement('tr');
                csvBullet.innerHTML = `<td>CSV:</td><td><input value="${csvLink}" /></td><td><a href="${csvLink}" title="Download CSV">file_download</a></td>`;
                csvBullet.querySelector('td:last-of-type').addEventListener('click', (evt) => {
                    evt.preventDefault();
                    trackPageview({url: `https://heatmap.data.gov.scot/csv/${srcLyr.get('iShare:layerName')}`}, {callback: () => window.location = evt.target.href});
                });
                ogcLinks.appendChild(csvBullet);
                if (getGroupContainingLayer(map.getLayerGroup(), srcLyr).get('title') == "Energy Supply") {
                    var singleCsvHeading = document.createElement('tr');
                    singleCsvHeading.innerHTML = '<td colspan="3">All energy supply technologies:</td>';
                    var singleCsvUrl = `/downloads/EnergySupply.csv`;
                    var singleCsvLink = document.createElement('a');
                    singleCsvLink.href = singleCsvUrl;
                    singleCsvLink.innerText = 'CSV (all technologies)';
                    singleCsvLink.target = '_blank';
                    var singleCsvBullet = document.createElement('tr');
                    singleCsvBullet.innerHTML = `<td>CSV:</td><td><input value="${singleCsvLink}" /></td><td><a href="${singleCsvLink}" title="Download CSV - all technologies">file_download</a></td>`;
                    singleCsvBullet.querySelector('td:last-of-type').addEventListener('click', (evt) => {
                        evt.preventDefault();
                        trackPageview({url: `https://heatmap.data.gov.scot/csv/energy_supply`}, {callback: () => window.location = evt.target.href});
                    });
                    ogcLinks.appendChild(singleCsvHeading);
                    ogcLinks.appendChild(singleCsvBullet);
                }
            }
            if (geotiffLayers.includes(srcLyr.get('iShare:layerName'))) {
                var geotiffBullet = document.createElement('tr');
                geotiffBullet.innerHTML = `<td>TIFF:</td><td><input value="${geotiffLink}" /></td><td><a href="${geotiffLink}" title="Download GeoTIFF">file_download</a></td>`;
                geotiffBullet.querySelector('td:last-of-type').addEventListener('click', (evt) => {
                    evt.preventDefault();
                    trackPageview({url: `https://heatmap.data.gov.scot/geotiff/${srcLyr.get('iShare:layerName')}`}, {callback: () => window.location = evt.target.href});
                });
                ogcLinks.appendChild(geotiffBullet);
            }
            var ogcList = document.createElement('div');
            ogcList.id = 'ogcList';
            ogcList.innerHTML = '<h1>Web services/downloads</h1>';
            ogcList.appendChild(ogcLinks);
            panel.querySelector(".panel").appendChild(ogcList);
        }
        
        // Zoom to layer extent (from metadata)
        var extentLink = panel.querySelector('#extentDiv');
        if (extentLink) {
            extentLink.parentNode.removeChild(extentLink);
        }
        var extentDiv = document.createElement('div');
        extentDiv.id = 'extentDiv';
        var extentLink = document.createElement('button');
        extentLink.id = 'extentLink';
        extentLink.innerText = 'Zoom to layer extent';
        extentLink.addEventListener('click', function() {
            let sourceBBox = srcLyr.get('iShare:config').metadata.bbox;
            let destBBox = transformExtent(
                [sourceBBox.maxy, sourceBBox.minx, sourceBBox.miny, sourceBBox.maxx],
                sourceBBox.crs,
                'EPSG:27700'
            );
            map.getView().fit( 
                destBBox,
                {duration: 1000, padding: [10, 10, 10, 10]}
            );
        });
        extentDiv.appendChild(extentLink);
        panel.querySelector(".panel").appendChild(extentDiv);

        // Link to metadata record on external CSW site (from metadata)
        var metadataDiv = panel.querySelector('#metadataDiv');
        if (metadataDiv) {
            metadataDiv.parentNode.removeChild(metadataDiv);
        }
        var metadataDiv = document.createElement('div');
        metadataDiv.id = 'metadataDiv';
        var metadataLink = document.createElement('button');
        metadataLink.id = 'metadataLink';
        metadataLink.innerText = 'Layer metadata';
        metadataLink.addEventListener('click', function() {
            trackPageview({url: `https://heatmap.data.gov.scot/metadata/${srcLyr.get('iShare:layerName')}`});
            window.open(metadataURL, '_blank');
        })
        metadataDiv.appendChild(metadataLink);
        panel.querySelector('.panel').appendChild(metadataDiv);
    }
};

export {LayerInfoPanel};
