{
  "name": "simple",
  "dependencies": {
    "@walkermatt/better-jsonp": "^1.1.2",
    "axios": "^0.24.0",
    "copy-to-clipboard": "^3.3.1",
    "lodash-es": "^4.17.21",
    "mk-toast": "^2.0.1",
    "ol-ishare": "file:packages/ol-ishare-1.1.1-unstable.tgz",
    "plausible-tracker": "^0.3.4"
  },
  "devDependencies": {
    "parcel": "2.0.0-beta.2",
    "parcel-transformer-svelte": "1.1.1"
  },
  "scripts": {
    "start": "parcel serve index.html",
    "build": "parcel build --public-url . index.html"
  }
}
