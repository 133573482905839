<script>

  export let length;
  export let area;
  export let mode = 'choose';

  // Allows dispatching events via our parent (MeasurePanel)
  export let dispatch = (evt) => {};

  function setMode(mode_) {
    mode = mode_;
    dispatch(mode);
  }

</script>

<main>
  {#if mode === 'choose'}
    <button class="toggle" on:click={(evt) => setMode('measure-length')}>Measure length</button>
    <button class="toggle" on:click={(evt) => setMode('measure-area')}>Meaure area</button>
  {:else}
    {#if mode === 'measure-length'}
      <dl>
        <dt>Length:</dt>
        {#if length}
        <dd>{length}</dd>
        {:else}
        <dd>Click on the map to start drawing.</dd>
        {/if}
      </dl>
    {/if}
    {#if mode === 'measure-area'}
      <dl>
        <dt>Area:</dt>
        {#if area}
        <dd>{area}</dd>
        {:else}
        <dd>Click on the map to start drawing.</dd>
        {/if}
        <dt>Perimeter:</dt>
        {#if length}
        <dd>{length}</dd>
        {:else}
        <dd>-</dd>
        {/if}
      </dl>
    {/if}
  {/if}
    <button class="toggle closer" on:click={(evt) => setMode('close')}>&times; Close measure tool</button>
</main>
