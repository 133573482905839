import {transform, transformExtent} from 'ol/proj';
import jsonp from '@walkermatt/better-jsonp';
import { containsCoordinate, containsExtent, intersects } from 'ol/extent';


function search(q) {
  var url =
    'https://a.services.astuntechnology.com/open/search/osopennames/?q=' + q;
  return jsonp({
    url: url,
    callbackParams: 'callback'
  }).then(function (results) {
    // Transform coords and bbox to British National Grid
    results.features = results.features.map(function (result) {
      var coords = transform(
        result.geometry.coordinates,
        'EPSG:4326',
        'EPSG:27700'
      );
      result.geometry.coordinates = coords;
      var bbox = transformExtent(result.bbox, 'EPSG:4326', 'EPSG:27700');
      result.bbox = bbox;
      return result;
    });
    results.features = results.features.filter(function (result) {
        var coords = result.geometry.coordinates;
        return withinProjectArea(coords);
    });
    return results;
  });
}

function createGazSource() {
  var source = {
    search: function (query) {
      return search(query).then(function (features) {
        var results = features.features
          .filter(function (feature) {
            // Discard features with a null search_full property
            return feature.properties.search_full != null;
          })
          .map(function (feature) {
            var result = {
              id: feature.properties.id,
              text: feature.properties.search_full,
              formatted: feature.properties.search_full,
              srs: 'EPSG:4326'
            };
            if (feature.bbox) {
              result.bbox = feature.bbox;
            } else {
              result.coord = geom.coordinates;
            }
            return result;
          });
        return results;
      });
    }
  };
  return source;
}

function withinProjectArea(coordsOrBbox) {
  var projectExtent = [130000, 10000, 657000, 659000];
  if (coordsOrBbox.length === 2) {
    return (
      coordsOrBbox[1] > 530244
    );
  } else {
    return (
      console.log(coordsOrBbox)
    );
  }
}

export { createGazSource };