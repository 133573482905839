import { Panel } from './panel.js';
import WelcomeView from './WelcomeView.svelte';

class WelcomePanel extends Panel {
  constructor(opt_options) {
    var options = opt_options || {};
    if (!options.className) {
      options.className = 'welcome-panel';
    }
    options.title = 'Welcome to the Scotland Heat Map Interactive';
    super(options);
    this.view = new WelcomeView({
      target: this.panel,
      props: {
        dispatch: (evt) => {
          this.dispatch(evt);
        }
      }
    });
  }

  handleCloseClick() {
    // Instead of hiding, dispatch an event and let the listener
    // determine if the panel should be hidden
    this.dispatchEvent({
      type: 'welcome-panel-close'
    });
  }

  show() {
    this.reset();
    super.show();
  }

  hide() {
    super.hide();
    this.reset();
  }

  reset() {
    this.setProps({
      mode: 'choose',
      length: null,
      area: null
    });
  }

  setProps(props) {
    this.view.$set(props);
  }

  dispatch(evt) {
    evt = (evt.type) ? evt : {type: evt};
    this.dispatchEvent(evt);
  }

}

export { WelcomePanel };
