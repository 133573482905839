import { Panel } from './panel.js';
import ReportView from './ReportView.svelte';

class ReportPanel extends Panel {
  constructor(opt_options) {
    var options = opt_options || {};
    if (!options.className) {
      options.className = 'report-panel';
    }
    options.title = 'Heat Demand Report';
    super(options);
    this.view = new ReportView({
      target: this.panel,
      props: {
      }
    });
    this.view.$on('choose', (evt) => {
      this.dispatchEvent({
        type: 'choose'
      });
    });
    this.view.$on('custom-draw', (evt) => {
      this.dispatchEvent({
        type: 'custom-draw'
      });
    });
    this.view.$on('Datazone-select', (evt) => {
      this.dispatchEvent({
        type: 'Datazone-select'
      });
    });
    this.view.$on('Settlement-select', (evt) => {
      this.dispatchEvent({
        type: 'Settlement-select'
      });
    });
    this.view.$on('LA-select', (evt) => {
      this.dispatchEvent({
        type: 'LA-select'
      });
    });
    this.view.$on('select-loading', (evt) => {
      this.dispatchEvent({
        type: 'select-loading'
      });
    });
    this.view.$on('print-report', (evt) => {
      this.dispatchEvent({
        type: 'print-report'
      });
    });
    this.view.$on('print-custom-report', (evt) => {
      this.dispatchEvent({
        type: 'print-custom-report'
      });
    });
    this.view.$on('download-csv', (evt) => {
      this.dispatchEvent({
        type: 'download-csv'
      });
    });
    this.view.$on('custom-clear', (evt) => {
      this.dispatchEvent({
        type: 'custom-clear'
      });
    });
    this.view.$on('datazone-clear', (evt) => {
      this.dispatchEvent({
        type: 'datazone-clear'
      });
    });
    this.view.$on('settlement-clear', (evt) => {
      this.dispatchEvent({
        type: 'settlement-clear'
      });
    });
    this.view.$on('la-clear', (evt) => {
      this.dispatchEvent({
        type: 'la-clear'
      });
    });
    this.view.$on('close', (evt) => {
      this.dispatchEvent({
        type: 'close'
      });
    });
  }

  show() {
    super.show();
  }

  hide() {
    super.hide();
    this.dispatchEvent({
      type: 'clear-report'
    });
    this.view.setMode('choose');
  }

  setMode(mode, data) {
    this.view.setMode(mode, data);
  }
}

export { ReportPanel };
